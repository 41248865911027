<template>
  <div></div>
</template>

<script>
export default {
  async mounted() {
    await this.$auth.logOut();
    this.$router.push('/login');
  },
};
</script>
